import format from 'date-fns/format';
import { WebComponentAPI } from 'ohw-app-container-external-component-host';
import qs from 'qs';
import {
  CANCELLATION_REASON_MAX_LENGTH as CANCELLATION_REASON_MAX_LENGTH_1_0,
  TASKS_PER_PAGE_MAX_VALUE as TASKS_PER_PAGE_MAX_VALUE_1_0,
  TASKS_PER_PAGE_MIN_VALUE as TASKS_PER_PAGE_MIN_VALUE_1_0,
  TASK_TITLE_ILLEGAL_CHARS as TASK_TITLE_ILLEGAL_CHARS_1_0,
  TASK_TITLE_MAX_LENGTH as TASK_TITLE_MAX_LENGTH_1_0,
} from '../model/1_0/Constants';
import {
  WrappedTaskDto10,
  WrappedTaskListDto10,
} from '../model/1_0/TaskDtos10';
import {
  CANCELLATION_REASON_MAX_LENGTH as CANCELLATION_REASON_MAX_LENGTH_1_1,
  TASKS_PER_PAGE_MAX_VALUE as TASKS_PER_PAGE_MAX_VALUE_1_1,
  TASKS_PER_PAGE_MIN_VALUE as TASKS_PER_PAGE_MIN_VALUE_1_1,
  TASK_TITLE_ILLEGAL_CHARS as TASK_TITLE_ILLEGAL_CHARS_1_1,
  TASK_TITLE_MAX_LENGTH as TASK_TITLE_MAX_LENGTH_1_1,
} from '../model/1_1/Constants';
import {
  WrappedTaskDto11,
  WrappedTaskListDto11,
} from '../model/1_1/TaskDtos11';
import {
  CANCELLATION_REASON_MAX_LENGTH as CANCELLATION_REASON_MAX_LENGTH_1_2,
  TASKS_PER_PAGE_MAX_VALUE as TASKS_PER_PAGE_MAX_VALUE_1_2,
  TASKS_PER_PAGE_MIN_VALUE as TASKS_PER_PAGE_MIN_VALUE_1_2,
  TASK_TITLE_ILLEGAL_CHARS as TASK_TITLE_ILLEGAL_CHARS_1_2,
  TASK_TITLE_MAX_LENGTH as TASK_TITLE_MAX_LENGTH_1_2,
} from '../model/1_2/Constants';
import {
  WrappedTaskDto12,
  WrappedTaskListDto12,
} from '../model/1_2/TaskDtos12';
import {
  AnyCancelDto,
  AnyCreateDto,
  AnyEditDto,
  AnyListDto,
  AnyScheduleDto,
  AnyWrappedTaskDto,
  AnyWrappedTaskListDto,
} from '../model/CommonTaskDtos';

export type TasksApiVersion = '1.0' | '1.1' | '1.2';

class TasksApiManager {
  private patient!: WebComponentAPI.Identifier;

  private apiVersion!: TasksApiVersion;

  private apiKey!: string;

  private mediaType!: string;

  private fetchFunction!: typeof fetch;

  private apiBase!: string;

  private csrfToken?: string;

  private authHeader?: string;

  init(
    patient: WebComponentAPI.Identifier,
    apiVersion: TasksApiVersion,
    apiKey: string,
    fetchFunction: typeof fetch,
    apiBase: string,
    csrfToken?: string,
    authHeader?: string
  ) {
    this.patient = patient;
    this.apiVersion = apiVersion;
    this.mediaType = `application/vnd.orchestral.tasks.${apiVersion.replace(
      '.',
      '_'
    )}+json`;
    this.apiKey = apiKey;
    this.fetchFunction = fetchFunction;
    this.apiBase = apiBase;
    this.csrfToken = csrfToken;
    this.authHeader = authHeader;
  }

  getApiVersion(): TasksApiVersion {
    return this.apiVersion;
  }

  listTasksForPatient(listDto: AnyListDto): Promise<AnyWrappedTaskListDto> {
    let urlToUse = this.getUrlToUse(
      `/patient/${this.patient.id}@${this.patient.namespace}/tasks`
    );

    const queryString = qs.stringify(listDto, {
      arrayFormat: 'comma',
      allowDots: true,
      addQueryPrefix: true,
    });
    if (queryString) {
      urlToUse += queryString;
    }

    const headers: HeadersInit = {
      Accept: this.mediaType,
      'X-OHP-Developer-API-Key': this.apiKey,
    };
    this.authHeader && (headers.Authorization = this.authHeader);

    return this.fetchFunction(urlToUse, {
      headers,
    })
      .then(TasksApiManager.handleInvalidResponseAndGetJson)
      .then(json => {
        if (this.apiVersion === '1.0') {
          return json as WrappedTaskListDto10;
        }
        if (this.apiVersion === '1.1') {
          return json as WrappedTaskListDto11;
        }
        return json as WrappedTaskListDto12;
      });
  }

  createTaskForPatient(
    createTaskDto: AnyCreateDto
  ): Promise<AnyWrappedTaskDto> {
    const urlToUse = this.getUrlToUse(
      `/patient/${this.patient.id}@${this.patient.namespace}/tasks`
    );

    let body = Object.assign({}, createTaskDto) as any;
    if (body.due) {
      body.due.dateTime = format(body.due.dateTime, "yyyy-MM-dd'T'HH:mm:ssxxx");
    }
    body = JSON.stringify(body);
    const headers: HeadersInit = {
      Accept: this.mediaType,
      'Content-Type': this.mediaType,
      'X-OHP-Developer-API-Key': this.apiKey,
    };
    this.csrfToken && (headers['CSRF-Token'] = this.csrfToken);
    this.authHeader && (headers.Authorization = this.authHeader);

    return this.fetchFunction(urlToUse, {
      method: 'POST',
      headers,
      body,
    })
      .then(TasksApiManager.handleInvalidResponseAndGetJson)
      .then(TasksApiManager.getResponseJsonAsTaskDto(this.apiVersion));
  }

  getTaskTitleMaxLengthForCurrentApiVersion(): number {
    if (this.apiVersion === '1.0') {
      return TASK_TITLE_MAX_LENGTH_1_0;
    }
    if (this.apiVersion === '1.1') {
      return TASK_TITLE_MAX_LENGTH_1_1;
    }
    return TASK_TITLE_MAX_LENGTH_1_2;
  }

  getTaskTitleIllegalCharsForCurrentApiVersion(): string[] {
    if (this.apiVersion === '1.0') {
      return TASK_TITLE_ILLEGAL_CHARS_1_0;
    }
    if (this.apiVersion === '1.1') {
      return TASK_TITLE_ILLEGAL_CHARS_1_1;
    }
    return TASK_TITLE_ILLEGAL_CHARS_1_2;
  }

  getCancellationReasonMaxLengthForCurrentApiVersion(): number {
    if (this.apiVersion === '1.0') {
      return CANCELLATION_REASON_MAX_LENGTH_1_0;
    }
    if (this.apiVersion === '1.1') {
      return CANCELLATION_REASON_MAX_LENGTH_1_1;
    }
    return CANCELLATION_REASON_MAX_LENGTH_1_2;
  }

  getTasksPerPageMinValueForCurrentApiVersion(): number {
    if (this.apiVersion === '1.0') {
      return TASKS_PER_PAGE_MIN_VALUE_1_0;
    }
    if (this.apiVersion === '1.1') {
      return TASKS_PER_PAGE_MIN_VALUE_1_1;
    }
    return TASKS_PER_PAGE_MIN_VALUE_1_2;
  }

  getTasksPerPageMaxValueForCurrentApiVersion(): number {
    if (this.apiVersion === '1.0') {
      return TASKS_PER_PAGE_MAX_VALUE_1_0;
    }
    if (this.apiVersion === '1.1') {
      return TASKS_PER_PAGE_MAX_VALUE_1_1;
    }
    return TASKS_PER_PAGE_MAX_VALUE_1_2;
  }

  editTaskForPatient(
    taskId: string,
    editDto: AnyEditDto
  ): Promise<AnyWrappedTaskDto> {
    const urlToUse = this.getUrlToUse(
      `/patient/${this.patient.id}@${this.patient.namespace}/tasks/${taskId}/edit`
    );

    const headers: HeadersInit = {
      Accept: this.mediaType,
      'Content-Type': this.mediaType,
      'X-OHP-Developer-API-Key': this.apiKey,
    };
    this.csrfToken && (headers['CSRF-Token'] = this.csrfToken);
    this.authHeader && (headers.Authorization = this.authHeader);

    return this.fetchFunction(urlToUse, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        title: editDto.title,
      }),
    })
      .then(TasksApiManager.handleInvalidResponseAndGetJson)
      .then(TasksApiManager.getResponseJsonAsTaskDto(this.apiVersion));
  }

  scheduleTaskForPatient(
    taskId: string,
    scheduleTaskDto: AnyScheduleDto
  ): Promise<AnyWrappedTaskDto> {
    const urlToUse = this.getUrlToUse(
      `/patient/${this.patient.id}@${this.patient.namespace}/tasks/${taskId}/schedule`
    );

    let body = Object.assign({}, scheduleTaskDto) as any;
    if (body.due) {
      body.due.dateTime = format(body.due.dateTime, "yyyy-MM-dd'T'HH:mm:ssxxx");
    }
    body = JSON.stringify(body);
    const headers: HeadersInit = {
      Accept: 'application/vnd.orchestral.tasks.1_0+json',
      'Content-Type': 'application/vnd.orchestral.tasks.1_0+json',
      'X-OHP-Developer-API-Key': this.apiKey,
    };
    this.csrfToken && (headers['CSRF-Token'] = this.csrfToken);
    this.authHeader && (headers.Authorization = this.authHeader);

    return this.fetchFunction(urlToUse, {
      method: 'PUT',
      headers,
      body,
    })
      .then(TasksApiManager.handleInvalidResponseAndGetJson)
      .then(TasksApiManager.getResponseJsonAsTaskDto(this.apiVersion));
  }

  cancelTaskForPatient(
    taskId: string,
    cancelTaskDto: AnyCancelDto
  ): Promise<AnyWrappedTaskDto> {
    const urlToUse = this.getUrlToUse(
      `/patient/${this.patient.id}@${this.patient.namespace}/tasks/${taskId}/cancel`
    );

    const headers: HeadersInit = {
      Accept: this.mediaType,
      'Content-Type': this.mediaType,
      'X-OHP-Developer-API-Key': this.apiKey,
    };
    this.csrfToken && (headers['CSRF-Token'] = this.csrfToken);
    this.authHeader && (headers.Authorization = this.authHeader);

    return this.fetchFunction(urlToUse, {
      method: 'PUT',
      headers,
      body: JSON.stringify({
        reason: cancelTaskDto.reason,
      }),
    })
      .then(TasksApiManager.handleInvalidResponseAndGetJson)
      .then(TasksApiManager.getResponseJsonAsTaskDto(this.apiVersion));
  }

  completeTaskForPatient(taskId: string): Promise<AnyWrappedTaskDto> {
    const urlToUse = this.getUrlToUse(
      `/patient/${this.patient.id}@${this.patient.namespace}/tasks/${taskId}/complete`
    );

    const headers: HeadersInit = {
      Accept: this.mediaType,
      'X-OHP-Developer-API-Key': this.apiKey,
    };
    this.csrfToken && (headers['CSRF-Token'] = this.csrfToken);
    this.authHeader && (headers.Authorization = this.authHeader);

    return this.fetchFunction(urlToUse, {
      method: 'PUT',
      headers,
    })
      .then(TasksApiManager.handleInvalidResponseAndGetJson)
      .then(TasksApiManager.getResponseJsonAsTaskDto(this.apiVersion));
  }

  private getUrlToUse(relUrl: string): string {
    const absUrl = new URL(relUrl, this.apiBase);
    return absUrl.hostname === 'localhost' ? relUrl : absUrl.href;
  }

  private static handleInvalidResponseAndGetJson(
    response: Response
  ): Promise<object> {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  }

  private static getResponseJsonAsTaskDto(
    apiVersion: TasksApiVersion
  ): (json: object) => AnyWrappedTaskDto {
    return (json: object) => {
      if (apiVersion === '1.0') {
        return json as WrappedTaskDto10;
      }
      if (apiVersion === '1.1') {
        return json as WrappedTaskDto11;
      }
      return json as WrappedTaskDto12;
    };
  }
}

// export a singleton instance
const tasksApiManager = new TasksApiManager();
export default tasksApiManager;
