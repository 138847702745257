import { Button, Typography, withStyles } from '@material-ui/core';
import { formatDistanceToNow } from 'date-fns';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import CancelTaskDialog from '../components/cancelTaskDialog/CancelTaskDialog';
import CreateTaskDialog from '../components/createTaskDialog/CreateTaskDialog';
import EditTaskDialog from '../components/editTaskDialog/EditTaskDialog';
import FilterTableDialog from '../components/filterTableDialog/FilterTableDialog';
import TaskTable from '../components/taskTable/TaskTable';
import { DATE_FNS_LOCALE_BY_I18N_LANGUAGE } from '../constants';
import ComponentContextContext from '../context/ComponentContext';
import {
  AnyCancelDto,
  AnyCreateDto,
  AnyEditDto,
  AnyFilterQueryParams,
  AnyListDto,
  AnyPageQueryParams,
  AnyScheduleDto,
  AnyWrappedTaskListDto,
} from '../model/CommonTaskDtos';
import TasksApiManager from '../network/TasksApiManager';
import { TasksComponentConfig } from '../OhwTasksWebUIComponent';

const EMPTY_LIST_TASKS_RESPONSE: AnyWrappedTaskListDto = {
  data: [],
  links: [],
  annotations: {
    informationTypes: [],
    privacyAccessLevel: {
      code: 'FullAccess',
      description: 'Full Access',
    },
  },
};

const WhiteTextTypography = withStyles({
  root: {
    color: 'white',
  },
})(Typography);
const getNotistackDismissAction = (
  closeSnackbar: WithSnackbarProps['closeSnackbar']
) => (key: string | number) => (
  <>
    <Button onClick={() => closeSnackbar(key)}>
      <WhiteTextTypography>Dismiss</WhiteTextTypography>
    </Button>
  </>
);

interface TaskTableContainerProps extends WithSnackbarProps, WithTranslation {
  componentConfig: TasksComponentConfig;
}

type TaskTableContainerState = {
  failedToRetrieveTasks: boolean;
  showCreateTaskDialog: boolean;
  showCancelTaskDialog: boolean;
  showEditTaskDialog: boolean;
  showFilterTableDialog: boolean;
  taskToCancelId: string;
  taskToCancelTitle: string;
  taskToEditId: string;
  taskToEditTitle: string;
  taskToEditDue: string;
  filters: AnyFilterQueryParams;
} & AnyPageQueryParams;

class TaskTableContainer extends PureComponent<
  TaskTableContainerProps,
  TaskTableContainerState
> {
  context!: React.ContextType<typeof ComponentContextContext>;

  // eslint-disable-next-line react/sort-comp
  static contextType = ComponentContextContext;

  private tableRef: React.RefObject<any>;

  constructor(props: TaskTableContainerProps) {
    super(props);
    this.state = {
      failedToRetrieveTasks: false,
      showCreateTaskDialog: false,
      showCancelTaskDialog: false,
      showEditTaskDialog: false,
      showFilterTableDialog: false,
      taskToCancelId: '',
      taskToCancelTitle: '',
      taskToEditId: '',
      taskToEditTitle: '',
      taskToEditDue: '',
      filters: {},
      tasksPerPage: 5,
    };
    this.tableRef = React.createRef();
  }

  componentDidUpdate(
    _prevProps: TaskTableContainerProps,
    prevState: TaskTableContainerState
  ) {
    const { filters } = this.state;
    const { filters: prevFilters } = prevState;

    if (!isEqual(filters, prevFilters)) {
      this.refreshTableData();
    }
  }

  // eslint-disable-next-line react/sort-comp
  refreshTableData = (query?: AnyPageQueryParams) =>
    this.tableRef.current?.onQueryChange(query);

  // task editing handlers
  handleEditTaskButtonClicked = (
    taskId: string,
    originalTaskTitle: string,
    originalTaskDue: string
  ) => {
    this.setState({
      showEditTaskDialog: true,
      taskToEditId: taskId,
      taskToEditTitle: originalTaskTitle,
      taskToEditDue: originalTaskDue,
    });
  };

  handleEditDialogHide = () => {
    this.setState({
      showEditTaskDialog: false,
      taskToEditTitle: '',
      taskToEditDue: '',
      taskToEditId: '',
    });
  };

  handleTaskEdit = (
    editTaskDto: AnyEditDto | null,
    scheduleTaskDto: AnyScheduleDto | null
  ) => {
    if (editTaskDto === null && scheduleTaskDto === null) {
      // This should never be called because the 'Edit task' button
      // is disabled if no changes have been made in the Edit Task Dialog.
      return;
    }

    const { taskToEditId, taskToEditTitle } = this.state;
    const { t, i18n, enqueueSnackbar, closeSnackbar } = this.props;
    const notistackDismissAction = getNotistackDismissAction(closeSnackbar);
    /* It is possible to edit the title and/or the due date
    These calls must be made sequentially because the Tasks
    API does not support concurrent updates: see TSK-734. */
    TaskTableContainer.editTask(taskToEditId, editTaskDto)
      .then(editSucceeded => {
        return TaskTableContainer.scheduleTask(
          taskToEditId,
          scheduleTaskDto
        ).then(scheduleSucceeded => [editSucceeded, scheduleSucceeded]);
      })
      .then(([editSucceeded, scheduleSucceeded]) => {
        const bothSucceeded = editSucceeded && scheduleSucceeded;
        const bothFailed =
          editSucceeded === false && scheduleSucceeded === false;
        const oneOrBothSucceeded = editSucceeded || scheduleSucceeded;

        oneOrBothSucceeded && this.refreshTableData();

        if (bothSucceeded) {
          enqueueSnackbar(
            t('snackbar.success.edit-and-schedule-task', {
              taskTitle: taskToEditTitle,
            }),
            { variant: 'success' }
          );
        } else if (bothFailed) {
          enqueueSnackbar(
            t('snackbar.failure.edit-and-schedule-task', {
              taskTitle: taskToEditTitle,
            }),
            {
              variant: 'error',
              persist: true,
              action: notistackDismissAction,
            }
          );
        } else {
          if (editSucceeded) {
            enqueueSnackbar(
              t('snackbar.success.edit-task', {
                oldTaskTitle: taskToEditTitle,
                newTaskTitle: editTaskDto!.title,
              }),
              { variant: 'success' }
            );
          } else if (editSucceeded === false) {
            enqueueSnackbar(
              t('snackbar.failure.edit-task', {
                taskTitle: taskToEditTitle,
              }),
              {
                variant: 'error',
                persist: true,
                action: notistackDismissAction,
              }
            );
          }
          if (scheduleSucceeded) {
            const dateFnsDate = new Date(scheduleTaskDto!.due.dateTime);
            const due = formatDistanceToNow(dateFnsDate, {
              locale: DATE_FNS_LOCALE_BY_I18N_LANGUAGE[i18n.language],
            });
            enqueueSnackbar(
              t('snackbar.success.schedule-task', {
                taskTitle: editSucceeded ? editTaskDto!.title : taskToEditTitle,
                due,
              }),
              { variant: 'success' }
            );
          } else if (scheduleSucceeded === false) {
            enqueueSnackbar(
              t('snackbar.failure.schedule-task', {
                taskTitle: editSucceeded ? editTaskDto!.title : taskToEditTitle,
              }),
              {
                variant: 'error',
                persist: true,
                action: notistackDismissAction,
              }
            );
          }
        }
      });
    this.handleEditDialogHide();
  };

  // task cancellation handlers
  handleCancelTaskButtonClicked = (taskId: string, taskTitle: string) => {
    this.setState({
      showCancelTaskDialog: true,
      taskToCancelId: taskId,
      taskToCancelTitle: taskTitle,
    });
  };

  handleCancelDialogHide = () => {
    this.setState({
      showCancelTaskDialog: false,
      taskToCancelId: '',
      taskToCancelTitle: '',
    });
  };

  handleTaskCancel = (reason: string) => {
    const { taskToCancelId, taskToCancelTitle } = this.state;
    this.cancelTask(taskToCancelId, taskToCancelTitle, {
      reason,
    });
    this.handleCancelDialogHide();
  };

  // task creation handlers
  handleTaskCreate = (createDto: AnyCreateDto) => {
    this.createTask(createDto);
    this.handleCreateDialogHide();
  };

  handleCreateTaskButtonClicked = () => {
    this.setState({ showCreateTaskDialog: true });
  };

  handleCreateDialogHide = () => {
    this.setState({ showCreateTaskDialog: false });
  };

  handleFilterButtonClicked = () => {
    this.setState({ showFilterTableDialog: true });
  };

  handleFilterTableDialogHide = () => {
    this.setState({ showFilterTableDialog: false });
  };

  handleApplyFiltersClicked = (filters: AnyFilterQueryParams) => {
    this.handleFilterTableDialogHide();
    this.setState({ filters });
  };

  handleResetFiltersClicked = () => {
    this.handleFilterTableDialogHide();
    this.setState({ filters: {} });
  };

  handleFilterDelete = <K extends keyof AnyFilterQueryParams>(
    filterKey: K,
    value: any
  ) => {
    const { filters } = this.state;
    const filter = filters[filterKey];
    if (filter) {
      if (Array.isArray(filter)) {
        const filteredFilter = without(filter, value);
        if (filteredFilter.length < filter.length) {
          this.setState({
            filters: {
              ...filters,
              [filterKey]: filteredFilter,
            },
          });
        }
      } else {
        this.setState({
          filters: {
            ...filters,
            [filterKey]: undefined,
          },
        });
      }
    }
  };

  handlePageSizeChange = (tasksPerPage: number) => {
    this.setState({ tasksPerPage });
  };

  listTasks = (listOptions: AnyListDto) =>
    TasksApiManager.listTasksForPatient(listOptions)
      .then(data => {
        this.setState({ failedToRetrieveTasks: false });
        return data;
      })
      .catch(() => {
        this.setState({ failedToRetrieveTasks: true });
        return EMPTY_LIST_TASKS_RESPONSE;
      });

  createTask(taskToCreateDto: AnyCreateDto) {
    const { t, enqueueSnackbar, closeSnackbar } = this.props;
    const notistackDismissAction = getNotistackDismissAction(closeSnackbar);
    return TasksApiManager.createTaskForPatient(taskToCreateDto)
      .then(() => {
        this.refreshTableData();
        enqueueSnackbar(
          t('snackbar.success.create-task', {
            taskTitle: taskToCreateDto.title,
          }),
          { variant: 'success' }
        );
      })
      .catch(() => {
        enqueueSnackbar(
          t('snackbar.failure.create-task', {
            taskTitle: taskToCreateDto.title,
          }),
          { variant: 'error', persist: true, action: notistackDismissAction }
        );
      });
  }

  // eslint-disable-next-line react/sort-comp
  static editTask(
    taskId: string,
    taskToEditDto: AnyEditDto | null
  ): Promise<boolean | null> {
    if (!taskToEditDto) {
      return Promise.resolve(null);
    }
    return TasksApiManager.editTaskForPatient(taskId, taskToEditDto)
      .then(() => true)
      .catch(() => false);
  }

  // eslint-disable-next-line react/sort-comp
  static scheduleTask(
    taskId: string,
    taskToScheduleDto: AnyScheduleDto | null
  ): Promise<boolean | null> {
    if (!taskToScheduleDto) {
      return Promise.resolve(null);
    }
    return TasksApiManager.scheduleTaskForPatient(taskId, taskToScheduleDto)
      .then(() => true)
      .catch(() => false);
  }

  completeTask(taskId: string, taskTitle: string) {
    const { t, enqueueSnackbar, closeSnackbar } = this.props;
    const notistackDismissAction = getNotistackDismissAction(closeSnackbar);
    return TasksApiManager.completeTaskForPatient(taskId)
      .then(() => {
        this.refreshTableData();
        enqueueSnackbar(
          t('snackbar.success.complete-task', {
            taskTitle,
          }),
          { variant: 'success' }
        );
      })
      .catch(() => {
        enqueueSnackbar(
          t('snackbar.failure.complete-task', {
            taskTitle,
          }),
          { variant: 'error', persist: true, action: notistackDismissAction }
        );
      });
  }

  cancelTask(taskId: string, taskTitle: string, taskToCancelDto: AnyCancelDto) {
    const { t, enqueueSnackbar, closeSnackbar } = this.props;
    const notistackDismissAction = getNotistackDismissAction(closeSnackbar);
    return TasksApiManager.cancelTaskForPatient(taskId, taskToCancelDto)
      .then(() => {
        this.refreshTableData();
        enqueueSnackbar(
          t('snackbar.success.cancel-task', {
            taskTitle,
          }),
          { variant: 'success' }
        );
      })
      .catch(() => {
        enqueueSnackbar(
          t('snackbar.failure.cancel-task', {
            taskTitle,
          }),
          { variant: 'error', persist: true, action: notistackDismissAction }
        );
      });
  }

  render() {
    const {
      failedToRetrieveTasks,
      showCreateTaskDialog,
      showCancelTaskDialog,
      showEditTaskDialog,
      showFilterTableDialog,
      taskToEditTitle,
      taskToEditDue,
    } = this.state;
    const { filters, tasksPerPage } = this.state;

    return (
      <div id="taskTableWrapper">
        {showCreateTaskDialog && (
          <div>
            <CreateTaskDialog
              hideDialog={this.handleCreateDialogHide}
              createTask={this.handleTaskCreate}
            />
          </div>
        )}
        {showCancelTaskDialog && (
          <CancelTaskDialog
            hideDialog={this.handleCancelDialogHide}
            cancelTask={this.handleTaskCancel}
          />
        )}
        {showEditTaskDialog && (
          <EditTaskDialog
            originalTaskTitle={taskToEditTitle}
            originalTaskDue={taskToEditDue}
            hideDialog={this.handleEditDialogHide}
            editTask={this.handleTaskEdit}
          />
        )}
        {showFilterTableDialog && (
          <FilterTableDialog
            hideDialog={this.handleFilterTableDialogHide}
            applyFilters={this.handleApplyFiltersClicked}
            filters={filters}
          />
        )}
        <TaskTable
          tableRef={this.tableRef}
          failedToRetrieveTasks={failedToRetrieveTasks}
          onAddTask={this.handleCreateTaskButtonClicked}
          onEditTask={(
            taskId: string,
            originalTaskTitle: string,
            originalTaskDue: string
          ) =>
            this.handleEditTaskButtonClicked(
              taskId,
              originalTaskTitle,
              originalTaskDue
            )
          }
          onCancelTask={(taskId: string, taskTitle: string) =>
            this.handleCancelTaskButtonClicked(taskId, taskTitle)
          }
          onCompleteTask={(taskId: string, taskTitle: string) =>
            this.completeTask(taskId, taskTitle)
          }
          onListTasks={(listOptions: AnyListDto) => this.listTasks(listOptions)}
          onFilterTable={this.handleFilterButtonClicked}
          onFilterDelete={this.handleFilterDelete}
          onFiltersReset={this.handleResetFiltersClicked}
          filters={filters}
          pageSize={tasksPerPage!}
          onPageSizeChange={this.handlePageSizeChange}
        />
      </div>
    );
  }
}

export default withTranslation()(withSnackbar(TaskTableContainer));
