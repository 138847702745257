import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusString, TaskStatus } from '../../model/CommonTaskDtos';
import TaskStatusChip from '../TaskStatusChip';

const useStyles = makeStyles({
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: '0.5rem',
    },
  },
});

const StatusFilter = ({
  value = [],
  onClick,
}: {
  value: StatusString[] | undefined;
  onClick: React.EventHandler<any>;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Typography gutterBottom variant="body1">
        {t(`task-table.filter-dialog.field.label.status`)}
      </Typography>
      <div className={classes.chipContainer}>
        {Object.keys(TaskStatus).map((statusKey: string) => {
          const status = statusKey as TaskStatus;
          return (
            <TaskStatusChip
              key={status}
              status={status}
              onClick={onClick}
              selected={value.indexOf(status as StatusString) !== -1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StatusFilter;
