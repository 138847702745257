import { MTableToolbar } from 'material-table';
import React from 'react';
import { AnyFilterQueryParams } from '../../model/CommonTaskDtos';
import { TaskTableFilters } from '../taskTable/TaskTable';
import FilterBar from './FilterBar';

interface TableToolbarProps {
  filters: TaskTableFilters;
  onFilterDelete: <K extends keyof AnyFilterQueryParams>(
    filterKey: K,
    value: any
  ) => void;
  onFiltersReset: () => void;
  toolbar: any;
}

/**
 * Material table toolbar override to render custom filter bar for displaying
 * table filters in a material design compliant way.
 *
 * Renders the default table toolbar and the FilterBar component that
 * hooks into the table via the table helper to get the current filter state
 */
const TableToolbar = ({
  filters,
  onFilterDelete,
  onFiltersReset,
  toolbar,
}: TableToolbarProps) => {
  return (
    <>
      <MTableToolbar {...toolbar} />
      <FilterBar
        filters={filters}
        onFilterDelete={onFilterDelete}
        onFiltersReset={onFiltersReset}
      />
    </>
  );
};

export default TableToolbar;
