import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { addHours } from 'date-fns';
import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  DATE_FNS_HUMAN_READABLE_DATE_TIME_FORMAT,
  DATE_FNS_LOCALE_BY_I18N_LANGUAGE,
} from '../constants';

interface DueDateTimePickerProps extends WithTranslation {
  onChange: React.Dispatch<React.SetStateAction<Date | null>>;
  dueDate: Date | null;
  error?: boolean;
}

const DueDateTimePicker: FC<DueDateTimePickerProps> = ({
  onChange,
  dueDate,
  error,
  t,
  i18n,
}: DueDateTimePickerProps) => {
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={DATE_FNS_LOCALE_BY_I18N_LANGUAGE[i18n.language]}
    >
      <DateTimePicker
        disablePast
        lang={i18n.language}
        fullWidth
        margin="normal"
        label={t('due-date-time-picker.due-input.label')}
        id="due-date-time-picker"
        value={dueDate}
        onChange={onChange}
        error={error}
        helperText={
          error
            ? t('due-date-time-picker.due-input.validation.in-the-past')
            : ''
        }
        emptyLabel={t('due-date-time-picker.due-input.empty-label')}
        minutesStep={5}
        // if there is no initial due date set, make it one hour in the future so it isn't instantly invalid because it's in the past
        initialFocusedDate={
          dueDate === null ? addHours(new Date(Date.now()), 1) : dueDate
        }
        format={DATE_FNS_HUMAN_READABLE_DATE_TIME_FORMAT}
        okLabel={t('due-date-time-picker.ok-button.label')}
        cancelLabel={t('due-date-time-picker.cancel-button.label')}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withTranslation()(DueDateTimePicker);
