import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskStatus } from '../../model/CommonTaskDtos';
import { TaskTableFilters } from '../taskTable/TaskTable';
import StatusFilter from './StatusFilter';

interface FilterDialogProps {
  filters: TaskTableFilters;
  hideDialog: (event: React.MouseEvent<HTMLButtonElement>) => void;
  applyFilters: (
    filters: TaskTableFilters,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

const useStyles = makeStyles({
  content: {
    minWidth: '20rem',
  },
});

const FilterDialog = ({
  filters = { status: undefined },
  applyFilters,
  hideDialog,
}: FilterDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filterState, setFilterState] = useState(filters);

  useEffect(() => {
    setFilterState(filters);
  }, [filters]);

  const onStatusChange = (status: TaskStatus) => {
    const filterValue = filterState.status ?? [];
    const mutatableValues = [...filterValue];
    const valueIndex = mutatableValues.indexOf(status);
    if (valueIndex === -1) {
      mutatableValues.push(status);
    } else {
      mutatableValues.splice(valueIndex, 1);
    }

    setFilterState(state => ({
      ...state,
      status: mutatableValues,
    }));
  };

  const handleApply = (event: React.MouseEvent<HTMLButtonElement>) => {
    applyFilters(filterState, event);
  };

  const isFilterClean = filterState === filters;

  return (
    <Dialog open onClose={hideDialog} disableBackdropClick>
      <DialogContent className={classes.content}>
        <List>
          <ListItem dense>
            <StatusFilter value={filterState.status} onClick={onStatusChange} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog} color="primary">
          {t('task-table.filter-dialog.actions.cancel')}
        </Button>
        <Button onClick={handleApply} disabled={isFilterClean} color="primary">
          {t('task-table.filter-dialog.actions.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
