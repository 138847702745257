import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TasksApiManager from '../../network/TasksApiManager';

interface CancelTaskDialogProps {
  hideDialog: () => void;
  cancelTask: (reason: string) => void;
}

const styles = makeStyles(() => ({
  sensitiveMessage: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    marginBottom: '16px',
  },
  warningIcon: {
    color: '#ff9800', // theme.palette.warning main,
  },
}));

const CancelTaskDialog: FC<CancelTaskDialogProps> = ({
  hideDialog,
  cancelTask,
}: CancelTaskDialogProps) => {
  const { t } = useTranslation();
  const classes = styles();
  const [reason, setReason] = useState('');

  const cancellationReasonMaxLengthForCurrentApiVersion: number = TasksApiManager.getCancellationReasonMaxLengthForCurrentApiVersion();
  const reasonIsTooLong =
    reason.length > cancellationReasonMaxLengthForCurrentApiVersion;
  const reasonIsBlank = reason.trim() === '';
  const reasonIsProvided = !!reason;

  // Cancellation reason is only valid when it is provided, and it is not blank, or too long.
  const reasonIsInvalid =
    reasonIsProvided && (reasonIsBlank || reasonIsTooLong);

  let cancellationReasonValidationMessage = '';
  if (reasonIsInvalid) {
    if (reasonIsBlank) {
      cancellationReasonValidationMessage = t(
        'cancel-task-dialog.reason-input.validation.blank'
      );
    } else if (reasonIsTooLong) {
      cancellationReasonValidationMessage = t(
        'cancel-task-dialog.reason-input.validation.too-long',
        {
          cancellationReasonMaxLengthForCurrentApiVersion,
        }
      );
    }
  }

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open
      onClose={hideDialog}
      disableBackdropClick
    >
      <DialogContent>
        <Typography className={classes.sensitiveMessage}>
          <WarningIcon className={classes.warningIcon} />
          {t('cancel-task-dialog.reason-input.sensitive-data')}
        </Typography>
        <TextField
          id="cancel-task-dialog-reason-input"
          autoFocus
          error={reasonIsInvalid}
          required
          fullWidth
          label={t('cancel-task-dialog.reason-input.label')}
          value={reason}
          onChange={event => setReason(event.target.value ?? '')}
          helperText={
            cancellationReasonValidationMessage ||
            t('cancel-task-dialog.reason-input.validation.required')
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog} color="primary">
          {t('cancel-task-dialog.back-button.label')}
        </Button>
        <Button
          color="primary"
          onClick={() => cancelTask(reason)}
          disabled={!reasonIsProvided || reasonIsInvalid}
        >
          {t('cancel-task-dialog.cancel-task-button.label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CancelTaskDialog);
