import TaskDto10, {
  CancelDto10,
  CreateDto10,
  EditDto10,
  FilterQueryParams10,
  ListDto10,
  PageQueryParams10,
  QueryMap10,
  ScheduleDto10,
  SortQueryParams10,
  WrappedTaskDto10,
  WrappedTaskListDto10,
} from './1_0/TaskDtos10';
import TaskDto11, {
  CancelDto11,
  CreateDto11,
  EditDto11,
  FilterQueryParams11,
  ListDto11,
  PageQueryParams11,
  QueryMap11,
  ScheduleDto11,
  SortQueryParams11,
  WrappedTaskDto11,
  WrappedTaskListDto11,
} from './1_1/TaskDtos11';
import TaskDto12, {
  CancelDto12,
  CreateDto12,
  EditDto12,
  FilterQueryParams12,
  ListDto12,
  PageQueryParams12,
  QueryMap12,
  ScheduleDto12,
  SortQueryParams12,
  WrappedTaskDto12,
  WrappedTaskListDto12,
} from './1_2/TaskDtos12';

export enum TaskStatus {
  ready = 'ready',
  'in-progress' = 'in-progress',
  completed = 'completed',
  cancelled = 'cancelled',
  abandoned = 'abandoned',
}

type TaskStatusToColorMap = {
  [key in keyof typeof TaskStatus]: {
    borderColor: string;
    backgroundColor: string;
    hoverColor: string;
  };
};

const taskStatusToColorMap: TaskStatusToColorMap = {
  ready: {
    backgroundColor: '#d8e7f2',
    borderColor: '#0062ae',
    hoverColor: '#ecfbff',
  },
  'in-progress': {
    backgroundColor: '#fbedd8',
    borderColor: '#e88b00',
    hoverColor: '#ffffec',
  },
  completed: {
    backgroundColor: '#d8ead8',
    borderColor: '#007400',
    hoverColor: '#ecfeec',
  },
  abandoned: {
    backgroundColor: '#f5d8d8',
    borderColor: '#be0000',
    hoverColor: '#ffecec',
  },
  cancelled: {
    backgroundColor: '#f6f6f6',
    borderColor: '#888888',
    hoverColor: '#ffffff',
  },
};

export type StatusString = keyof typeof TaskStatus;

interface Name {
  family: string[];
  given: string[];
  text: string;
}

interface Identifier {
  id: string;
  namespace: string;
}

export interface IdentityDto {
  name: Name;
  resolvingIdentifier: Identifier;
}

export interface DateTimeDto {
  dateTime: Date;
  timezone: string;
}

enum SupportedLinks {
  self,
  previous,
  next,
  start,
  assign,
  schedule,
  complete,
  cancel,
  reopen,
  edit,
  'orionhealth:describe-redacted-resources',
}
export type Links = {
  [key in keyof typeof SupportedLinks]?: {
    href: string;
  };
};

type PrivacyAccessLevelCode =
  | 'NoAccess'
  | 'Locked'
  | 'ListMore'
  | 'Sealed'
  | 'SealOpen'
  | 'FullAccess';
type PrivacyAccessLevelDescription =
  | 'No Access'
  | 'Locked'
  | 'List More'
  | 'Sealed'
  | 'Seal Open'
  | 'Full Access';
interface PrivacyAccessLevel {
  code: PrivacyAccessLevelCode;
  description: PrivacyAccessLevelDescription;
}

interface InformationType {
  informationType: {
    code: string;
    name: string;
    description: string;
  };
  accessLevel: PrivacyAccessLevel;
}

export interface Annotations {
  informationTypes: InformationType[];
  privacyAccessLevel: PrivacyAccessLevel;
}

export type AnyTaskDto = TaskDto10 | TaskDto11 | TaskDto12;
export type AnyListDto = ListDto10 | ListDto11 | ListDto12;
export type AnyCreateDto = CreateDto10 | CreateDto11 | CreateDto12;
export type AnyCancelDto = CancelDto10 | CancelDto11 | CancelDto12;
export type AnyEditDto = EditDto10 | EditDto11 | EditDto12;
export type AnyScheduleDto = ScheduleDto10 | ScheduleDto11 | ScheduleDto12;
export type AnyWrappedTaskDto =
  | WrappedTaskDto10
  | WrappedTaskDto11
  | WrappedTaskDto12;
export type AnyWrappedTaskListDto =
  | WrappedTaskListDto10
  | WrappedTaskListDto11
  | WrappedTaskListDto12;

export type AnyQueryMap = QueryMap10 | QueryMap11 | QueryMap12;

export type AnyFilterQueryParams =
  | FilterQueryParams10
  | FilterQueryParams11
  | FilterQueryParams12;
export type AnySortQueryParams =
  | SortQueryParams10
  | SortQueryParams11
  | SortQueryParams12;
export type AnyPageQueryParams =
  | PageQueryParams10
  | PageQueryParams11
  | PageQueryParams12;

export { taskStatusToColorMap };
