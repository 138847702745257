import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusString, taskStatusToColorMap } from '../model/CommonTaskDtos';

interface TaskStatusChipProps {
  status: StatusString;
  onDelete?: React.EventHandler<any>;
  selected?: boolean;
  onClick?: React.EventHandler<any>;
}

const useStyles = makeStyles({
  chip: ({ status }: TaskStatusChipProps) => ({
    backgroundColor: taskStatusToColorMap[status].backgroundColor,
    color: taskStatusToColorMap[status].borderColor,
    borderColor: taskStatusToColorMap[status].borderColor,
    minWidth: '100px',
    // Centers chip content when chip text is smaller minWidth and delete
    // icon is displayed. Outside of this condition theres no behaviour change
    '& > .MuiChip-label': {
      flex: '1 1 0',
      textAlign: 'center',
    },
    '& > .MuiChip-Avatar': {
      flex: '1 1 0',
      textAlign: 'center',
    },
    '&.MuiChip-clickable,&.MuiChip-deletable': {
      '&:hover,&:focus': {
        backgroundColor: taskStatusToColorMap[status].hoverColor,
      },
    },
  }),
  icon: ({ status }: TaskStatusChipProps) => ({
    '&.MuiSvgIcon-root': {
      color: taskStatusToColorMap[status].borderColor,
    },
  }),
});

const TaskStatusChip: FC<TaskStatusChipProps> = ({
  status,
  onDelete,
  onClick,
  selected,
}: TaskStatusChipProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ status });

  const handleDelete =
    onDelete &&
    (() => {
      onDelete(status);
    });

  const handleClick = onClick && (() => onClick(status));

  const deleteIcon = <CancelIcon className={classes.icon} />;
  const avatarIcon = selected ? (
    <DoneIcon className={classes.icon} />
  ) : (
    undefined
  );

  return (
    <Chip
      variant="outlined"
      className={classes.chip}
      avatar={avatarIcon}
      label={t(`status.${status}`)}
      deleteIcon={deleteIcon}
      onDelete={handleDelete}
      clickable={!!handleClick}
      onClick={handleClick}
    />
  );
};

export default memo(TaskStatusChip);
