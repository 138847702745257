import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyFilterQueryParams, StatusString } from '../../model/CommonTaskDtos';
import TaskStatusChip from '../TaskStatusChip';

const useStyle = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 1rem',
    '& .MuiChip-root': {
      margin: '0.2rem 1rem 0.2rem 0',
    },
  },
  resetContainer: {
    display: 'flex',
    flexWrap: 'nowrap', // don't wrap the chip - link groups
    flex: '0 0 auto', // default the 'reset' containers width to it's required width then don't change it
  },
});

export interface FilterBarProps {
  filters: AnyFilterQueryParams;
  onFilterDelete: <K extends keyof AnyFilterQueryParams>(
    filterKey: K,
    value: any
  ) => void;
  onFiltersReset: () => void;
}

const FilterBar: FC<FilterBarProps> = ({
  filters,
  onFilterDelete,
  onFiltersReset,
}: FilterBarProps) => {
  const classes = useStyle();
  const { t } = useTranslation();

  // don't render anything if we have nothing to render
  if (isEmpty(filters?.status)) {
    return null;
  }

  const chips = (filters?.status ?? []).map((status: StatusString) => {
    return (
      <TaskStatusChip
        key={status}
        status={status}
        onDelete={() => onFilterDelete('status', status)}
      />
    );
  });

  return (
    <Box className={`${classes.container} ohw-quick-tasks-filterbar`}>
      {chips.slice(0, Math.max(1, chips.length - 1))}
      {chips.length > 1 && (
        <Box className={classes.resetContainer}>
          {chips.slice(chips.length - 1, chips.length)}
          <Link component="button" onClick={onFiltersReset}>
            {t('task-table.filter-bar.links.reset')}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default FilterBar;
