import { WebComponentAPI } from 'ohw-app-container-external-component-host';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import initI18n from './i18n';
import TasksApiManager, { TasksApiVersion } from './network/TasksApiManager';
import OhwTasksReactComponent from './OhwTasksReactComponent';
import * as serviceWorker from './serviceWorker';

export interface TasksComponentConfig {
  tasksApiVersion: TasksApiVersion;
  tasksApiKey: string;
  tasksApiBase: string;
  tasksApiAuthHeader?: string;
  isHostedByComponentHost?: boolean;
}

// taken from https://stackoverflow.com/a/21125098/2155605
const getConcertoLocaleCookie = (): string => {
  const match = document.cookie.match(
    new RegExp(`(^| )ConcertoLocale=([^;]+)`)
  );
  if (match) {
    return match[2];
  }
  return 'en';
};

class OhwTasksWebUIComponent extends WebComponentAPI.WebUIComponent<
  TasksComponentConfig
> {
  constructor(
    config: WebComponentAPI.WebUIComponentProps<TasksComponentConfig>
  ) {
    super(config);

    initI18n(getConcertoLocaleCookie());

    /**
     * In the Component Host deployment scenario, trade an OHP JWT for an AWS JWT.
     * This assumes that there is an OHP JWT Generation Profile with an issuer
     * named 'quick-tasks' and that uses the RS256 signing algorithm.
     */
    if (config.componentConfig.isHostedByComponentHost) {
      config.componentHost
        .request(
          new URL(
            'session-token/quick-tasks',
            config.componentConfig.tasksApiBase
          ).href
        )
        .then(response => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(responseJson => {
          config.componentHost
            .request('/jwt', {
              headers: {
                Authorization: `Bearer ${responseJson['session-token']}`,
              },
            })
            .then(response => {
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              return response.text();
            })
            .then(jwtToken => {
              /**
               * TODO In the future when we implement the metrics service,
               * this JWT token (and the JWT token retrieved via the C4X
               * token in the standalone deployment scenario) will be
               * passed to an API manager used to call the metrrics service.
               */
            })
            .catch(error => {
              throw new Error(error);
            });
        })
        .catch(error => {
          throw new Error(error);
        });
    }

    // Init the TasksApiManager singleton
    const { patientIdentifier, csrfToken } = config.componentContext;
    const { request } = config.componentHost;
    const {
      componentConfig: {
        tasksApiVersion,
        tasksApiKey,
        tasksApiBase,
        tasksApiAuthHeader,
      },
    } = config;
    TasksApiManager.init(
      patientIdentifier,
      tasksApiVersion,
      tasksApiKey,
      request,
      tasksApiBase,
      csrfToken,
      tasksApiAuthHeader
    );
  }

  render(onComplete: (error?: string | undefined) => void): this | void {
    ReactDOM.render(
      <Suspense fallback="Loading translations...">
        <OhwTasksReactComponent {...this.props} />
      </Suspense>,
      this.props.container,
      onComplete
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  }
}

export { OhwTasksWebUIComponent as Component };
export { OhwTasksReactComponent as ReactComponent };
