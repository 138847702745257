import { Box } from '@material-ui/core';
import {
  StyleRules,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { format, formatDistanceToNow, isPast } from 'date-fns';
import compareAsc from 'date-fns/compareAsc';
import MaterialTable, {
  Column,
  Localization,
  Query,
  QueryResult,
} from 'material-table';
import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  DATE_FNS_HUMAN_READABLE_DATE_TIME_FORMAT,
  DATE_FNS_LOCALE_BY_I18N_LANGUAGE,
} from '../../constants';
import {
  AnyFilterQueryParams,
  AnyListDto,
  AnyWrappedTaskDto,
  AnyWrappedTaskListDto,
  Links,
  StatusString,
} from '../../model/CommonTaskDtos';
import TableToolbar from '../filterToolbar/TableToolbar';
import TaskStatusChip from '../TaskStatusChip';

const convertWrappedTaskDtoToTaskTableData = (
  taskDto: AnyWrappedTaskDto
): TaskTableData => ({
  id: taskDto.data.id,
  title: taskDto.data.title,
  description: taskDto.data.description ?? '',
  status: taskDto.data.status,
  due: taskDto.data.due ? taskDto.data.due.dateTime.toString() : '',
  links: taskDto.links,
});

/**
 * Material Table toolbar has margin left 24px on desktop view and 16px
 * on mobile view. In order to make title align with the first column on both cases,
 * we use breakpoints to detect screen size.
 */
const taskTableStyles = (theme: Theme): StyleRules => ({
  titleContainer: {
    [theme.breakpoints.up(600)]: {
      marginLeft: '-8px',
    },
  },
});

export interface TaskTableData {
  id: string;
  title: string;
  description: string;
  status: StatusString;
  due: string;
  links: Links;
}

export interface TaskTableFilters {
  status?: AnyFilterQueryParams['status'];
}

// this is so we can also set the material-table data prop to a simple array for testing
interface MaterialTablePromiseDataProp {
  (query: Query<TaskTableData>): Promise<QueryResult<TaskTableData>>;
}
type MaterialTableDataProp = MaterialTablePromiseDataProp | TaskTableData[];

interface TaskTableProps extends WithTranslation, WithStyles {
  tableRef: React.RefObject<any>;
  failedToRetrieveTasks: boolean;
  onAddTask: () => void;
  onCompleteTask: (taskId: string, taskTitle: string) => void;
  onCancelTask: (taskId: string, taskTitle: string) => void;
  onEditTask: (
    taskId: string,
    originalTaskTitle: string,
    originalTaskDue: string
  ) => void;
  onFilterTable: () => void;
  onListTasks: (
    listOptions: AnyListDto
  ) => Promise<AnyWrappedTaskListDto | void>;
  onPageSizeChange: (pageSize: number) => void;
  onFilterDelete: <K extends keyof AnyFilterQueryParams>(
    filterKey: K,
    value: any
  ) => void;
  onFiltersReset: () => void;
  materialTableDataProp?: MaterialTableDataProp; // exposed for testing
  filters: TaskTableFilters;
  pageSize: number;
}

class TaskTable extends PureComponent<TaskTableProps> {
  private fetchTasksDataFromMaterialTableQuery = (
    query: Query<TaskTableData>
  ): Promise<QueryResult<TaskTableData>> => {
    const { onListTasks, filters } = this.props;
    const listOptions: AnyListDto = {
      page: query.page + 1,
      pagesize: query.pageSize,
      category: 'todo',
      status: filters.status,
    };
    if (query.orderDirection === 'asc') {
      listOptions.sort = 'due';
    } else if (query.orderDirection === 'desc') {
      listOptions.sort = '-due';
    }

    return new Promise((resolve, reject) => {
      onListTasks(listOptions)
        .then(wrappedTaskListDto => {
          if (wrappedTaskListDto) {
            resolve({
              data: wrappedTaskListDto.data.map(
                convertWrappedTaskDtoToTaskTableData
              ),
              page: query.page,
              // This is to prevent material-table's infinite pagination problem when using remote data
              totalCount:
                wrappedTaskListDto.data.length < query.pageSize
                  ? (query.page + 1) * query.pageSize
                  : (query.page + 1) * query.pageSize + 1,
            });
          }
        })
        .catch(error => {
          reject(new Error(error));
        });
    });
  };

  render() {
    const {
      failedToRetrieveTasks,
      onAddTask,
      onCancelTask,
      onCompleteTask,
      onEditTask,
      onFilterTable,
      onPageSizeChange,
      onFilterDelete,
      onFiltersReset,
      materialTableDataProp = this.fetchTasksDataFromMaterialTableQuery,
      filters,
      pageSize,
      tableRef,
      t,
      i18n,
      classes,
    }: TaskTableProps = this.props;

    const statusTranslations: Record<string, string> = t('status', {
      returnObjects: true,
    });

    const columns: Column<TaskTableData>[] = [
      {
        title: t('task-table.column-header.title'),
        field: 'title',
        sorting: false,
        render: (data: TaskTableData) => <Typography>{data.title}</Typography>,
      },
      {
        title: t('task-table.column-header.status'),
        field: 'status',
        sorting: false,
        lookup: statusTranslations,
        render: (data: TaskTableData | string, type) => {
          if (data === undefined) {
            return undefined;
          }
          if (type === 'row') {
            return <TaskStatusChip status={(data as TaskTableData).status} />;
          }
          const stringToTaskStatus: string | undefined = Object.keys(
            statusTranslations
          ).find(key => statusTranslations[key] === (data as string));
          return <TaskStatusChip status={stringToTaskStatus as StatusString} />;
        },
      },
      {
        title: t('task-table.column-header.due'),
        field: 'due',
        type: 'datetime',
        defaultSort: 'asc',
        customSort: (taskDto1: TaskTableData, taskDto2: TaskTableData) => {
          if (!taskDto1.due || !taskDto2.due) {
            return -1;
          }
          return compareAsc(new Date(taskDto1.due), new Date(taskDto2.due));
        },
        render: (taskDto: TaskTableData) => {
          let result;
          if (taskDto.due) {
            let dueElement;
            // have to wrap in a new date otherwise date-fns complains
            const dateFnsDate = new Date(taskDto.due);
            if (isPast(dateFnsDate)) {
              dueElement = (
                <Typography
                  color={
                    ['ready', 'in-progress'].includes(taskDto.status)
                      ? 'error'
                      : 'initial'
                  }
                >
                  {t('task-table.due.overdue', {
                    timeValue: formatDistanceToNow(dateFnsDate, {
                      locale: DATE_FNS_LOCALE_BY_I18N_LANGUAGE[i18n.language],
                    }),
                  })}
                </Typography>
              );
            } else {
              dueElement = (
                <Typography>
                  {t('task-table.due.not-overdue', {
                    timeValue: formatDistanceToNow(dateFnsDate, {
                      locale: DATE_FNS_LOCALE_BY_I18N_LANGUAGE[i18n.language],
                    }),
                  })}
                </Typography>
              );
            }
            const toolTip = format(
              new Date(taskDto.due),
              DATE_FNS_HUMAN_READABLE_DATE_TIME_FORMAT,
              {
                locale: DATE_FNS_LOCALE_BY_I18N_LANGUAGE[i18n.language],
              }
            );
            result = (
              <Tooltip title={toolTip} placement="bottom-start">
                {dueElement}
              </Tooltip>
            );
          } else {
            result = <Typography>{t('task-table.due.empty')}</Typography>;
          }
          return result;
        },
      },
    ];

    const tableTranslations: Localization = t('task-table.built-in', {
      returnObjects: true,
    });
    if (failedToRetrieveTasks && tableTranslations.body) {
      tableTranslations.body.emptyDataSourceMessage = t(
        'task-table.failedDataSourceMessage'
      );
    }

    const Toolbar = (props: any) => {
      return (
        <TableToolbar
          toolbar={props}
          filters={filters}
          onFilterDelete={onFilterDelete}
          onFiltersReset={onFiltersReset}
        />
      );
    };

    return (
      <MaterialTable
        title={
          <Typography component="div" className={classes.titleContainer}>
            <Box fontSize="20px" fontWeight="fontWeightBold">
              {t('task-table.title')}
            </Box>
          </Typography>
        }
        tableRef={tableRef}
        columns={columns}
        data={materialTableDataProp}
        components={{
          Toolbar,
        }}
        actions={[
          {
            icon: 'refresh',
            tooltip: t('task-table.actions.refresh.tooltip'),
            isFreeAction: true,
            onClick: () => {
              // This is how the material table documentation recommends refreshing
              tableRef.current && tableRef.current.onQueryChange();
            },
          },
          {
            icon: 'add',
            tooltip: t('task-table.actions.create.tooltip'),
            isFreeAction: true,
            onClick: () => {
              onAddTask();
            },
          },
          {
            icon: 'filter_list',
            tooltip: t('task-table.actions.filter.tooltip'),
            isFreeAction: true,
            onClick: () => {
              onFilterTable();
            },
          },
          (rowData: TaskTableData) => ({
            tooltip: t('task-table.actions.edit.tooltip'),
            icon: 'edit',
            onClick: () => onEditTask(rowData.id, rowData.title, rowData.due),
            disabled: !('edit' in rowData.links),
          }),
          (rowData: TaskTableData) => ({
            tooltip: t('task-table.actions.complete.tooltip'),
            icon: 'done',
            onClick: () => onCompleteTask(rowData.id, rowData.title),
            disabled: !('complete' in rowData.links),
          }),
          (rowData: TaskTableData) => ({
            tooltip: t('task-table.actions.cancel.tooltip'),
            icon: 'clear',
            onClick: () => onCancelTask(rowData.id, rowData.title),
            disabled: !('cancel' in rowData.links),
          }),
        ]}
        options={{
          header: true,
          grouping: false,
          selection: false,
          filtering: false,
          sorting: true,
          paging: true,
          actionsColumnIndex: -1,
          columnsButton: false,
          exportButton: false,
          addRowPosition: 'first',
          pageSize,
          pageSizeOptions: [5, 10, 20, 50, 100],
          paginationType: 'stepped',
          search: false,
          maxBodyHeight: window.innerHeight / 1.5,
          emptyRowsWhenPaging: false,
          rowStyle: (_rowData, index) => ({
            backgroundColor: index % 2 === 0 ? '#EEE' : '#FFF',
          }),
          headerStyle: {
            fontSize: '20px',
            fontWeight: 'bold',
          },
          // @ts-ignore material-table@1.53.0 does not define this type but it exists
          thirdSortClick: false,
        }}
        onChangeRowsPerPage={onPageSizeChange}
        localization={tableTranslations}
      />
    );
  }
}

export default withTranslation()(withStyles(taskTableStyles)(TaskTable));
