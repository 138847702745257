// 'react-app-polyfill/ie11' has to be on the top line to ensure IE11 works
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {
  AppContainer,
  WebComponentAPI,
} from 'ohw-app-container-external-component-host';
import React from 'react';
import ReactDOM from 'react-dom';
import packageJson from '../package.json';
import './assets/index.scss';
import request from './network/request';
import { TasksApiVersion } from './network/TasksApiManager';
import {
  Component as OhwTasksWebUIComponent,
  TasksComponentConfig,
} from './OhwTasksWebUIComponent';

const rootElement = document.getElementById('root');

const renderAppContainer = () => {
  const externalHostProps: AppContainer.ExternalHostProps = {
    moduleMeta: {
      moduleName: packageJson.name,
      moduleVersion: packageJson.version,
      componentConfig: {
        // the app container external host will set this from the html meta tags
        isHostedByComponentHost: true,
      },
      componentTypes: [
        {
          type: 'web-ui',
          version: packageJson.version,
          export: 'Component',
        },
        {
          type: 'react',
          version: packageJson.version,
          export: 'ReactComponent',
        },
      ],
    },
    moduleImport: () => import('./OhwTasksWebUIComponent'),
    request,
  };

  ReactDOM.render(
    <AppContainer.ExternalHost {...externalHostProps} />,
    rootElement
  );
};

type EnvVars = {
  REACT_APP_PATIENT_ID: string;
  REACT_APP_PATIENT_NAMESPACE: string;
  REACT_APP_TASKS_API_BASE: string;
  REACT_APP_TASKS_API_VERSION: TasksApiVersion;
  REACT_APP_TASKS_API_KEY: string;
  REACT_APP_TASKS_API_AUTH_HEADER: string;
};

const validateAndGetEnvVars = (): EnvVars => {
  if (
    process.env.REACT_APP_TASKS_API_VERSION !== '1.0' &&
    process.env.REACT_APP_TASKS_API_VERSION !== '1.1' &&
    process.env.REACT_APP_TASKS_API_VERSION !== '1.2'
  ) {
    throw new Error(
      'The REACT_APP_TASKS_API_VERSION env variable must be either 1.0, 1.1, or 1.2.'
    );
  }
  const envVars: EnvVars = {
    REACT_APP_PATIENT_ID: process.env.REACT_APP_PATIENT_ID!,
    REACT_APP_PATIENT_NAMESPACE: process.env.REACT_APP_PATIENT_NAMESPACE!,
    REACT_APP_TASKS_API_BASE: process.env.REACT_APP_TASKS_API_BASE!,
    REACT_APP_TASKS_API_VERSION: process.env.REACT_APP_TASKS_API_VERSION!,
    REACT_APP_TASKS_API_KEY: process.env.REACT_APP_TASKS_API_KEY!,
    REACT_APP_TASKS_API_AUTH_HEADER: process.env
      .REACT_APP_TASKS_API_AUTH_HEADER!,
  };
  const undefinedEnvVars: string[] = [];
  Object.entries(envVars).forEach(([envName, envValue]) => {
    if (!envValue) {
      undefinedEnvVars.push(envName);
    }
  });
  if (undefinedEnvVars.length > 0) {
    throw new Error(
      `You must define values for the following environment variables: ${undefinedEnvVars}`
    );
  }
  return envVars;
};

const renderWebUIComponent = (
  config: WebComponentAPI.WebUIComponentProps<TasksComponentConfig>
) => {
  new OhwTasksWebUIComponent(config).render(() => {});
};

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  const {
    REACT_APP_PATIENT_ID,
    REACT_APP_PATIENT_NAMESPACE,
    REACT_APP_TASKS_API_BASE,
    REACT_APP_TASKS_API_VERSION,
    REACT_APP_TASKS_API_KEY,
    REACT_APP_TASKS_API_AUTH_HEADER,
  }: EnvVars = validateAndGetEnvVars();

  renderWebUIComponent({
    componentConfig: {
      tasksApiKey: REACT_APP_TASKS_API_KEY,
      tasksApiVersion: REACT_APP_TASKS_API_VERSION,
      tasksApiBase: REACT_APP_TASKS_API_BASE,
      tasksApiAuthHeader: REACT_APP_TASKS_API_AUTH_HEADER,
    },
    componentContext: {
      patientIdentifier: {
        id: REACT_APP_PATIENT_ID,
        namespace: REACT_APP_PATIENT_NAMESPACE,
      },
      userIdentifier: {
        id: 'rded',
        namespace: 'clinical-portal-user',
      },
      request,
    },
    componentHost: {
      router: {
        push: () => {},
        replace: () => {},
      },
      request,
    },
    container: rootElement,
  });
} else if (window.location.search.indexOf('encryptedRequest') !== -1) {
  /**
   *                 === STANDALONE DEPLOYMENT SCENARIO ===
   *
   * - The UI will be hosted on a different server (AWS) from OHP so calls to tasks:
   *    - need to take CORS into account
   *    - must be absolute (need tasksApiBase)
   *    - must supply correct media types (need tasksApiVersion)
   *    - require an API key (need tasksApiKey)
   * - CORS must be configured for calls to AWS
   * - Calls to AWS:
   *    - can be root-relative
   *    - will need to be authorized by a JWT obtained by exchanging a C4X token
   *
   * How:
   * - Call /token with C4X token to get a JWT token
   *    - call /config.json to get the environment config
   *    - call /patient-id to get the patient and set the locale into ConcertoLocale cookie
   * - render the Web UI Component
   */
  const fetchConfigJson = (jwtToken: string): Promise<TasksComponentConfig> =>
    fetch('/config.json', {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
  const fetchPatientId = (jwtToken: string): Promise<string> =>
    fetch(`/patient-id${window.location.search}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.text();
    });

  fetch(`/token${window.location.search}`, { method: 'POST' })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.text();
    })
    .then(jwtToken => {
      return Promise.all<TasksComponentConfig, string>([
        fetchConfigJson(jwtToken),
        fetchPatientId(jwtToken),
      ]).then(([configJson, patientIdentifier]) => {
        const [patientId, patientNamespace] = patientIdentifier.split('@');
        renderWebUIComponent({
          componentConfig: configJson,
          componentContext: {
            patientIdentifier: {
              id: patientId,
              namespace: patientNamespace,
            },
            userIdentifier: {
              id: '',
              namespace: '',
            },
            request,
          },
          componentHost: {
            router: {
              push: () => {},
              replace: () => {},
            },
            request,
          },
          container: rootElement,
        });
      });
    })
    .catch(error => {
      throw new Error(error);
    });
} else {
  /**
   *                 === COMPONENT HOST DEPLOYMENT SCENARIO ===
   *
   * - The UI will be hosted on a different server (AWS) from OHP so calls to tasks:
   *    - need to take CORS into account
   *    - must be absolute (need tasksApiBase)
   *    - must supply correct media types (need tasksApiVersion)
   *    - require an API key (need tasksApiKey)
   * - CORS must be configured for calls to AWS
   * - Calls to AWS:
   *    - can be root-relative
   *    - will need to be authorized by a JWT obtained by exchanging an OHP JWT
   *
   * How:
   * - TODO exchange OHP JWT for an AWS JWT
   * - Get environment config from componentConfig via Component Host
   * - Get Patient ID from componentContext via Component Host
   * - Get the locale from the ConcertoLocale cookie set by App Container via Component Host bootstrap config
   * - render the App Container
   */
  renderAppContainer();
}
