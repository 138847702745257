import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import isEqual from 'date-fns/isEqual';
import isPast from 'date-fns/isPast';
import parse from 'date-fns/parse';
import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyEditDto, AnyScheduleDto } from '../../model/CommonTaskDtos';
import TasksApiManager from '../../network/TasksApiManager';
import DueDateTimePicker from '../DueDateTimePicker';

interface EditTaskDialogProps {
  originalTaskTitle: string;
  originalTaskDue: string;
  hideDialog: () => void;
  editTask: (
    editTaskDto: AnyEditDto | null,
    scheduleTaskDto: AnyScheduleDto | null
  ) => void;
}

const styles = makeStyles(() => ({
  sensitiveMessage: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    marginBottom: '16px',
  },
  warningIcon: {
    color: '#ff9800', // theme.palette.warning main,
  },
}));

const generateEditTaskDto = (taskTitle: string): AnyEditDto => {
  const editTaskDto: AnyEditDto = {
    title: taskTitle,
  };
  if (taskTitle && taskTitle.trim() !== '') {
    editTaskDto.title = taskTitle;
  }
  return editTaskDto;
};

const generateScheduleTaskDto = (taskDue: Date): AnyScheduleDto => {
  return {
    due: {
      dateTime: taskDue,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };
};

const EditTaskDialog: FC<EditTaskDialogProps> = ({
  originalTaskTitle,
  originalTaskDue,
  hideDialog,
  editTask,
}: EditTaskDialogProps) => {
  const { t } = useTranslation();
  const classes = styles();

  const originalTaskDueDate =
    originalTaskDue === ''
      ? null
      : parse(originalTaskDue, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date());
  const [newTaskDue, setNewTaskDue] = useState<Date | null>(
    originalTaskDueDate
  );
  const taskDueIsInvalid = !newTaskDue ? false : isPast(newTaskDue);
  const newTaskDueEqualsToOriginalTaskDue = isEqual(
    newTaskDue || 0,
    originalTaskDueDate || 0
  );

  const [newTaskTitle, setTaskTitle] = useState(originalTaskTitle);
  const taskTitleMaxLengthForCurrentApiVersion: number = TasksApiManager.getTaskTitleMaxLengthForCurrentApiVersion();
  const newTaskTitleEqualsToOriginalTaskTitle =
    newTaskTitle === originalTaskTitle;
  const taskTitleIsTooLong =
    newTaskTitle.length > taskTitleMaxLengthForCurrentApiVersion;
  const taskTitleIllegalCharsForCurrentApiVersion: string[] = TasksApiManager.getTaskTitleIllegalCharsForCurrentApiVersion();
  const taskTitleContainsIllegalChar = taskTitleIllegalCharsForCurrentApiVersion.some(
    invalidChar => newTaskTitle.includes(invalidChar)
  );
  const taskTitleIsBlank = newTaskTitle.trim() === '';

  // task title is only valid if it isn't blank, too long, and doesn't contain characters which are forbidden
  const taskTitleIsInvalid =
    taskTitleIsBlank || taskTitleIsTooLong || taskTitleContainsIllegalChar;

  let taskTitleValidationMessage = '';
  if (taskTitleIsInvalid) {
    if (taskTitleIsBlank) {
      taskTitleValidationMessage = t(
        'edit-task-dialog.name-input.validation.blank'
      );
    } else if (taskTitleIsTooLong) {
      taskTitleValidationMessage = t(
        'edit-task-dialog.name-input.validation.too-long',
        { taskTitleMaxLengthForCurrentApiVersion }
      );
    } else if (taskTitleContainsIllegalChar) {
      taskTitleValidationMessage = t(
        'edit-task-dialog.name-input.validation.illegal-chars',
        {
          taskTitleIllegalCharsForCurrentApiVersion: taskTitleIllegalCharsForCurrentApiVersion.join(
            ' '
          ),
        }
      );
    }
  }

  return (
    <Dialog
      aria-labelledby="edit-task-dialog-title"
      open
      onClose={hideDialog}
      disableBackdropClick
    >
      <DialogContent>
        <Typography className={classes.sensitiveMessage}>
          <WarningIcon className={classes.warningIcon} />
          {t('edit-task-dialog.name-input.sensitive-data')}
        </Typography>
        <TextField
          id="edit-task-dialog-title-input"
          error={taskTitleIsInvalid}
          fullWidth
          label={t('edit-task-dialog.name-input.label')}
          value={newTaskTitle}
          onChange={event => setTaskTitle(event.target.value)}
          helperText={taskTitleValidationMessage}
          autoFocus
        />
        <DueDateTimePicker
          dueDate={newTaskDue}
          onChange={setNewTaskDue}
          error={taskDueIsInvalid && !newTaskDueEqualsToOriginalTaskDue}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog} color="primary">
          {t('edit-task-dialog.back-button.label')}
        </Button>
        <Button
          color="primary"
          onClick={() =>
            editTask(
              newTaskTitleEqualsToOriginalTaskTitle
                ? null
                : generateEditTaskDto(newTaskTitle),
              newTaskDueEqualsToOriginalTaskDue || newTaskDue === null
                ? null
                : generateScheduleTaskDto(newTaskDue)
            )
          }
          disabled={
            (newTaskTitleEqualsToOriginalTaskTitle &&
              newTaskDueEqualsToOriginalTaskDue) ||
            (taskDueIsInvalid && !newTaskDueEqualsToOriginalTaskDue) ||
            (taskTitleIsInvalid && !newTaskTitleEqualsToOriginalTaskTitle)
          }
        >
          {t('edit-task-dialog.edit-task-button.label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(EditTaskDialog);
