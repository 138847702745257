import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import isPast from 'date-fns/isPast';
import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyCreateDto } from '../../model/CommonTaskDtos';
import TasksApiManager from '../../network/TasksApiManager';
import DueDateTimePicker from '../DueDateTimePicker';

interface CreateTaskDialogProps {
  hideDialog: () => void;
  createTask: (createTaskDto: AnyCreateDto) => void;
}

const styles = makeStyles(() => ({
  sensitiveMessage: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    marginBottom: '16px',
  },
  warningIcon: {
    color: '#ff9800', // theme.palette.warning main,
  },
}));

const generateCreateTaskDto = (
  taskTitle: string,
  taskDue?: Date | null
): AnyCreateDto => {
  const createTaskDto: AnyCreateDto = {
    type: 'Quick Task',
  };
  if (taskTitle && taskTitle.trim() !== '') {
    createTaskDto.title = taskTitle;
  }
  if (taskDue) {
    createTaskDto.due = {
      dateTime: taskDue,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }
  return createTaskDto;
};

const CreateTaskDialog: FC<CreateTaskDialogProps> = ({
  hideDialog,
  createTask,
}: CreateTaskDialogProps) => {
  const { t } = useTranslation();
  const classes = styles();

  const [taskDue, setTaskDue] = useState<Date | null>(null);
  const taskDueIsInvalid = !taskDue ? false : isPast(taskDue);

  const [taskTitle, setTaskTitle] = useState('');
  const taskTitleMaxLengthForCurrentApiVersion: number = TasksApiManager.getTaskTitleMaxLengthForCurrentApiVersion();
  const taskTitleIsTooLong =
    taskTitle.length > taskTitleMaxLengthForCurrentApiVersion;
  const taskTitleIllegalCharsForCurrentApiVersion: string[] = TasksApiManager.getTaskTitleIllegalCharsForCurrentApiVersion();
  const taskTitleContainsIllegalChar = taskTitleIllegalCharsForCurrentApiVersion.some(
    invalidChar => taskTitle.includes(invalidChar)
  );
  const taskTitleIsBlank = taskTitle.trim() === '';
  const taskTitleIsProvided = !!taskTitle;

  // task title is only valid if it is provided, and it isn't blank, too long, and doesn't contain characters which are forbidden
  const taskTitleIsInvalid =
    taskTitleIsProvided &&
    (taskTitleIsBlank || taskTitleIsTooLong || taskTitleContainsIllegalChar);

  let taskTitleValidationMessage = '';
  if (taskTitleIsInvalid) {
    if (taskTitleIsBlank) {
      taskTitleValidationMessage = t(
        'create-task-dialog.name-input.validation.blank'
      );
    } else if (taskTitleIsTooLong) {
      taskTitleValidationMessage = t(
        'create-task-dialog.name-input.validation.too-long',
        { taskTitleMaxLengthForCurrentApiVersion }
      );
    } else if (taskTitleContainsIllegalChar) {
      taskTitleValidationMessage = t(
        'create-task-dialog.name-input.validation.illegal-chars',
        {
          taskTitleIllegalCharsForCurrentApiVersion: taskTitleIllegalCharsForCurrentApiVersion.join(
            ' '
          ),
        }
      );
    }
  }

  return (
    <Dialog
      aria-labelledby="create-task-dialog-title"
      open
      onClose={hideDialog}
      disableBackdropClick
    >
      <DialogContent>
        <Typography className={classes.sensitiveMessage}>
          <WarningIcon className={classes.warningIcon} />
          {t('create-task-dialog.name-input.sensitive-data')}
        </Typography>
        <TextField
          id="create-task-dialog-title-input"
          error={taskTitleIsInvalid}
          required
          fullWidth
          label={t('create-task-dialog.name-input.label')}
          value={taskTitle}
          onChange={event => setTaskTitle(event.target.value ?? '')}
          helperText={
            taskTitleValidationMessage ||
            t('create-task-dialog.name-input.validation.required')
          }
          autoFocus
        />
        <DueDateTimePicker
          dueDate={taskDue}
          onChange={setTaskDue}
          error={taskDueIsInvalid}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog} color="primary">
          {t('create-task-dialog.back-button.label')}
        </Button>
        <Button
          color="primary"
          onClick={() => createTask(generateCreateTaskDto(taskTitle, taskDue))}
          disabled={
            !taskTitleIsProvided || taskTitleIsInvalid || taskDueIsInvalid
          }
        >
          {t('create-task-dialog.create-task-button.label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CreateTaskDialog);
