import { enGB, enUS, es, fr } from 'date-fns/locale';

export const DATE_FNS_LOCALE_BY_I18N_LANGUAGE: {
  [key: string]: Locale;
} = {
  en: enUS,
  'en-GB': enGB,
  es,
  fr,
};

// See: https://date-fns.org/docs/format.
export const DATE_FNS_HUMAN_READABLE_DATE_TIME_FORMAT = 'PPPPp';
