import { SnackbarProvider } from 'notistack';
import { WebComponentAPI } from 'ohw-app-container-external-component-host';
import React, { FC, memo } from 'react';
import 'typeface-roboto';
import './assets/app.scss';
import TaskTableContainer from './containers/TaskTable';
import ComponentContextContext from './context/ComponentContext';
import { TasksComponentConfig } from './OhwTasksWebUIComponent';

const OhwTasksReactComponent: FC<WebComponentAPI.WebUIComponentProps<
  TasksComponentConfig
>> = ({
  componentConfig,
  componentContext,
}: WebComponentAPI.WebUIComponentProps<TasksComponentConfig>) => (
  <ComponentContextContext.Provider value={componentContext}>
    <SnackbarProvider>
      <div className="app">
        <TaskTableContainer componentConfig={componentConfig} />
      </div>
    </SnackbarProvider>
  </ComponentContextContext.Provider>
);

export default memo(OhwTasksReactComponent);
